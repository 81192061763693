import { useCallback, useEffect, useState } from 'react';

import { type IUploadFileModalProps } from '@gbs-monorepo-packages/common';

import { type IUploadDocumentProps } from '../../../../services/documentsFolder';
import { RadioButton } from './components/RadioButton';
import { UploadMultipleFileModal } from './components/UploadMultipleFileModal';

interface IAddDependentModalProps
  extends Omit<IUploadFileModalProps, 'onAccept' | 'onDecline'> {
  onAccept: (document: IUploadDocumentProps[]) => Promise<boolean>;
  onDecline?: () => void;
  errorOnAccept?: string;
  loading: boolean;
}

const imageExtensions = [
  '.jpg',
  '.jpeg',
  '.png',
  '.svg',
  '.gif',
  '.bmp',
  '.webp',
];
const videoExtensions = ['.mp4', '.mov', '.wmv', '.mkv', '.webm'];
const audioExtensions = ['.mp3', '.wav'];
const textExtensions = [
  '.csv',
  '.txt',
  '.htm',
  '.html',
  '.doc',
  '.docx',
  '.xls',
  '.xlsx',
  '.ppt',
  '.pptx',
  '.pdf',
];

export const AddDocumentModal = ({
  onAccept,
  onDecline,
  errorOnAccept,
  loading,
  ...props
}: IAddDependentModalProps): JSX.Element | null => {
  const [filesType, setFilesType] = useState('files');
  const [filesExtensions, setFilesExtensions] = useState({});

  const resetForm = useCallback(() => {
    setFilesType('files');
  }, []);

  useEffect(() => {
    if (filesType === 'files') {
      setFilesExtensions({
        'text/*': textExtensions,
      });
    } else if (filesType === 'images') {
      setFilesExtensions({
        'image/*': imageExtensions,
      });
    } else {
      setFilesExtensions({
        'video/*': videoExtensions,
        'audio/*': audioExtensions,
      });
    }
  }, [filesType]);

  const handleDecline = useCallback(() => {
    resetForm();
    onDecline?.();
  }, [onDecline, resetForm]);

  const handleAccept = useCallback(
    async (uploaded: File[], descriptions: string[], highlights: boolean[]) => {
      const documentsToUpload = uploaded.map((file, index) => ({
        name: descriptions[index]?.trim() ?? file.name,
        document: file,
        highlighted: highlights[index] ?? false,
      }));

      const result = await onAccept(documentsToUpload);
      if (result) {
        handleDecline();
      }
      return result;
    },
    [handleDecline, onAccept]
  );

  return (
    <UploadMultipleFileModal
      data-cy="upload-document"
      {...props}
      acceptText="Upload"
      mainText="Upload Document"
      onAccept={handleAccept}
      onDecline={handleDecline}
      dropzoneOptions={{
        ...props.dropzoneOptions,
        accept: filesExtensions,
      }}
      biggerModal
      onOpenChange={resetForm}
      errorMessage={errorOnAccept}
      disabled={loading}
    >
      <RadioButton
        value={filesType}
        onValue={(e) => {
          setFilesType(e);
        }}
      />
    </UploadMultipleFileModal>
  );
};
