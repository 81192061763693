import { type Property } from 'csstype';
import { type ReactNode } from 'react';

import {
  Container,
  ImageContainer,
  InitialContainer,
  InitialText,
  LazyImageCustom,
  TableCellCustom,
} from './styles';

export interface ITableImageCellProps {
  alt?: string;
  center?: boolean;
  children?: ReactNode;
  dataCy?: string;
  initialLikeImage?: string;
  src?: string;
  subtitle?: string;
  text?: string;
  textAlign?: Property.TextAlign;
  type?: 'bigLogo' | 'icon' | 'image';
}

export const TableImageCell = ({
  alt = 'Table image',
  center = false,
  children,
  dataCy = 'table-image-cell',
  src,
  subtitle,
  text,
  textAlign = 'left',
  type = 'image',
  initialLikeImage,
}: ITableImageCellProps): JSX.Element => {
  return (
    <Container center={center} data-cy={`${dataCy}-container`}>
      <ImageContainer data-cy={dataCy} type={type}>
        {initialLikeImage ? (
          <InitialContainer type={type}>
            <InitialText>{initialLikeImage}</InitialText>
          </InitialContainer>
        ) : (
          <LazyImageCustom dataCy={type} src={src} alt={alt} type={type}>
            {children}
          </LazyImageCustom>
        )}
      </ImageContainer>
      {text && (
        <TableCellCustom
          text={text}
          data-cy={dataCy}
          subtitle={subtitle}
          textAlign={textAlign}
        />
      )}
    </Container>
  );
};
