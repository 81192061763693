import { produce } from 'immer';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Can, useAuth } from '@gbs-monorepo-packages/auth';
import {
  BaseDropdown,
  BaseModal,
  Button,
  type IApiThrowsError,
  type IRole,
  NotViewerUsers,
  SearchBar,
  SelectData,
  Table,
  TableCell,
  TableImageCell,
  TableRow,
  clientAndEmployeeWithLevel,
  rolesWithLevel,
  rolesWithoutAdminWithLevel,
  translateUserRoleInRole,
  useToast,
} from '@gbs-monorepo-packages/common';

import PersonIcon from '../../assets/person.png';
import LoadingSpinnerAnimated from '../../assets/spinner.svg';
import { ContentPagination } from '../../components/ContentPagination';
import { DialogModal } from '../../components/DialogModal';
import { COMPANY_ID } from '../../constants/Env';
import {
  type UsersCreateSchema,
  type UsersUpdateSchema,
} from '../../formSchemas/userSchema';
import { type IPaginationMetaProps } from '../../services/api';
import {
  type IPaginationUserMembershipDTO,
  type IUserMembershipDto,
  deleteMember,
  getUsersByMembership,
  inviteUser,
  updateMember,
} from '../../services/users';
import Logger from '../../utils/logger';
import { ModalAddUsers } from './components/ModalAddUsers';
import { ModalUpdateUser } from './components/ModalUpdateUsers';
import {
  ButtonContainer,
  Center,
  DropdownButtonContainer,
  DropdownItem,
  Header,
  ItemGridCenter,
  Loading,
  LoadingContainer,
  MainContainer,
  MainContent,
  TitlePage,
  UserIcon,
} from './styles';

export interface ISelectClientsProps {
  onValueChange?: (value: string) => void;
}

interface IUserMembershipWithCurrentRoles extends IUserMembershipDto {
  currentRole?: IRole | null;
  rolesAux?: IRole[];
}

const AlmostRoles = NotViewerUsers;

const getCurrentRole = (userRoles: string[]): IRole | null => {
  let currentRole = null;
  for (const value of userRoles.values()) {
    const userRoleAux = translateUserRoleInRole[value] ?? null;

    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!userRoleAux) {
      continue;
    }

    if (!currentRole) {
      currentRole = userRoleAux;
    } else if (currentRole.level < userRoleAux.level) {
      currentRole = userRoleAux;
    }
  }

  return currentRole;
};

const headerColumns = [
  {
    id: 'user-data',
    name: 'User Name',
    textAlign: 'left',
  },
  {
    id: 'client-name',
    name: 'Client',
    textAlign: 'center',
  },
  {
    id: 'user-type',
    name: 'User Type',
    textAlign: 'left',
  },
  {
    id: 'options',
    name: '',
    textAlign: 'center',
  },
];

export const Members = (): JSX.Element => {
  const isSelectOpen = useRef(new Set());

  const { user } = useAuth();
  const [loadingMembers, setLoadingMembers] = useState(false);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [loadingInvite, setLoadingInvite] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [successRequest, setSuccessRequest] = useState(false);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogLoadingOpen, setDialogLoadingOpen] = useState(false);
  const [dialogEditOpen, setDialogEditOpen] = useState(false);

  const [users, setUsers] = useState<IUserMembershipWithCurrentRoles[]>([]);
  const [userToDelete, setUserToDelete] =
    useState<IUserMembershipWithCurrentRoles | null>(null);

  const [idMemberToEdit, setIdMemberToEdit] = useState<number>(-1);
  const [firstNameEdit, setFirstNameEdit] = useState('');
  const [lastNameEdit, setLastNameEdit] = useState('');
  const [emailEdit, setEmailEdit] = useState('');
  const [phoneEdit, setPhoneEdit] = useState('');
  const [roleEdit, setRoleEdit] = useState('');
  const [profileImage, setProfileImage] = useState('');

  const { addToast } = useToast();

  const [search, setSearch] = useState('');
  const lastSearch = useRef(search);
  const [paginationMeta, setPaginationMeta] =
    useState<IPaginationMetaProps | null>(null);

  const isModalDeleteOpen = !!userToDelete;

  const currentRole = useMemo(() => {
    const userRoles = user?.roles ?? [];
    if (!userRoles.length) {
      return null;
    }

    return getCurrentRole(userRoles);
  }, [user]);

  useEffect(() => {
    if (successRequest) {
      setTimeout(() => {
        setSuccessRequest(false);
      }, 2000);
    }
  }, [successRequest]);

  const getUsers = useCallback(
    (page: number, limit: number) => {
      setLoadingMembers(true);
      getUsersByMembership(page, limit, search ?? '')
        .then((response: IPaginationUserMembershipDTO) => {
          const userWithRolesAux = response.data.map((user) => {
            user.currentRole = getCurrentRole(user.roles);

            const roles =
              user.client?.id === Number(COMPANY_ID)
                ? rolesWithLevel
                : user.client?.isAgency === true
                ? rolesWithoutAdminWithLevel
                : clientAndEmployeeWithLevel;

            const rolesAux = roles.filter(
              ({ level }) => level <= (currentRole?.level ?? 0)
            );

            return {
              ...user,
              rolesAux,
            };
          });

          setUsers(userWithRolesAux);
          setPaginationMeta(response.meta);

          if (dialogOpen) {
            resetForm();
            addToast({
              title: 'Success',
              description: 'User has been added!',
              styleType: 'success',
              dataCy: 'success-toast',
            });
          }

          if (isModalDeleteOpen) {
            setLoadingDelete(false);
            setUserToDelete(null);
            addToast({
              title: 'Success',
              description: 'User deleted!',
              styleType: 'success',
              dataCy: 'success-toast',
            });
          }
        })
        .catch((error: IApiThrowsError) => {
          Logger.debug('error: ', error);
        })
        .finally(() => {
          setLoadingMembers(false);
        });
    },
    [addToast, dialogOpen, isModalDeleteOpen, search]
  );

  const handleDeclineDelete = useCallback(() => {
    setUserToDelete(null);
  }, []);

  const handleAcceptDelete = useCallback(() => {
    if (userToDelete && isModalDeleteOpen) {
      setLoadingDelete(true);
      void deleteMember(userToDelete.id)
        .then(() => {
          getUsers(1, 15);
          setSuccessRequest(true);
          setDialogOpen(false);
        })
        .catch(() => {
          setTimeout(() => {
            setLoadingDelete(false);
            setUserToDelete(null);
            addToast({
              title: 'Something went wrong',
              description: 'An error occurred while deleting the user',
              styleType: 'error',
              dataCy: 'error-toast',
            });
          }, 2000);
        });
    }
  }, [userToDelete, isModalDeleteOpen, getUsers, addToast]);

  const handleRoleSelectChange = (
    value: string,
    email: string,
    firstName: string,
    lastName: string,
    phone: string
  ) => {
    setDialogLoadingOpen(true);

    void updateMember({
      firstName,
      lastName,
      email,
      roles: Array(value),
      phone,
    })
      .then(() => {
        getUsers(1, 15);
        setDialogLoadingOpen(false);
        addToast({
          title: 'Success',
          description: 'User updated!',
          styleType: 'success',
          dataCy: 'success-toast',
        });
      })

      .catch((error: IApiThrowsError) => {
        setTimeout(() => {
          setLoadingInvite(false);
          let errorMessage = '';
          if (error.response && error.response.status >= 500) {
            errorMessage =
              'An error occurred. Please try again or contact Benefit Education support.';
          } else {
            errorMessage = error.response?.data.error.message ?? '';
          }
          addToast({
            title: 'Something went wrong',
            description: errorMessage,
            styleType: 'error',
            dataCy: 'error-toast',
          });
        }, 2000);
      });
  };

  const handleProfileChange = (newProfileSrc: string, userId: number) => {
    setUsers((prevUsers) =>
      prevUsers.map((user) => {
        if (user.id === userId) {
          return {
            ...user,
            accountFile: user.accountFile
              ? { ...user.accountFile, path: '' }
              : user.accountFile,
          };
        }
        return user;
      })
    );
    setProfileImage(newProfileSrc);
  };

  const inviteTeammateSubmit = useCallback(
    (data: UsersCreateSchema): Promise<boolean> => {
      setLoadingInvite(true);

      return inviteUser({
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        roles: [data.roles],
        clientId: Number(data.clientId),
        phone: data.phone,
        profile: data.profile,
      })
        .then(() => {
          getUsers(1, 15);
          setDialogOpen(false);
          return true;
        })
        .catch((error: IApiThrowsError) => {
          setTimeout(() => {
            let errorMessage = '';
            if (error.response && error.response.status >= 500) {
              errorMessage =
                'An error occurred. Please try again or contact Benefit Education support.';
            } else {
              errorMessage = error.response?.data.error.message ?? '';
            }
            addToast({
              title: 'Something went wrong',
              description: errorMessage,
              styleType: 'error',
              dataCy: 'error-toast',
            });
          }, 2000);
          return false;
        })
        .finally(() => {
          setLoadingInvite(false);
        });
    },
    [getUsers, setDialogOpen, addToast]
  );

  const handlerEditSubmit = useCallback(
    (data: UsersUpdateSchema): Promise<boolean> => {
      setLoadingEdit(true);

      return updateMember({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        roles: [],
        phone: data.phone ?? '',
        profile: data.profile,
      })
        .then(() => {
          getUsers(1, 15);
          setDialogEditOpen(false);
          addToast({
            title: 'Success',
            description: 'User updated!',
            styleType: 'success',
            dataCy: 'success-toast',
          });
          return true;
        })
        .catch((error: IApiThrowsError) => {
          Logger.debug('error: ', error);
          setTimeout(() => {
            setLoadingInvite(false);
            addToast({
              title: 'Something went wrong',
              description: 'An error occurred while updating the user',
              styleType: 'error',
              dataCy: 'error-toast',
            });
          }, 2000);
          return false;
        })
        .finally(() => {
          setLoadingEdit(false);
        });
    },
    [getUsers, addToast]
  );

  const handleOpenModalEdit = (data: IUserMembershipWithCurrentRoles) => {
    setDialogEditOpen(true);
    setIdMemberToEdit(data.id);
    setFirstNameEdit(data.firstName);
    setLastNameEdit(data.lastName);
    setEmailEdit(data.email);
    setPhoneEdit(data.phone ?? '');
    setRoleEdit(data.currentRole?.key ?? '');
    setProfileImage(data.accountFile?.path ?? '');
  };

  const handleOpenModalDelete = (data: IUserMembershipWithCurrentRoles) => {
    setUserToDelete(data);
  };

  const resetForm = () => {
    if (!isSelectOpen.current.size) {
      setLoadingInvite(false);
      setDialogOpen(false);
    }
  };

  const searchUsers = (searchByButton = false) => {
    if (!loadingMembers || searchByButton) {
      setLoadingMembers(true);
      const pageAux = searchByButton
        ? 0
        : Number((paginationMeta?.page ?? 0) > 0 ? paginationMeta?.page : 0);
      getUsersByMembership(pageAux + 1, 15, search)
        .then((response: IPaginationUserMembershipDTO) => {
          const userWithRolesAux = response.data.map((user) => {
            user.currentRole = getCurrentRole(user.roles);

            const roles =
              user.client?.id === Number(COMPANY_ID)
                ? rolesWithLevel
                : user.client?.isAgency === true
                ? rolesWithoutAdminWithLevel
                : clientAndEmployeeWithLevel;

            const rolesAux = roles.filter(
              ({ level }) => level <= (currentRole?.level ?? 0)
            );

            return {
              ...user,
              rolesAux,
            };
          });

          setUsers(
            produce((draft) => {
              draft.push(...userWithRolesAux);
            })
          );
          setPaginationMeta(response.meta);
        })
        .catch((error: IApiThrowsError) => {
          Logger.debug('error: ', error);
        })
        .finally(() => {
          setLoadingMembers(false);
        });
    }
  };

  useEffect(() => {
    if (!users.length) {
      getUsers(1, 15);
    }
  }, []);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (search.trim() !== lastSearch.current) {
      const execSearch = () => {
        lastSearch.current = search.trim();
        setUsers([]);
        searchUsers(true);
      };

      if (search.trim() === '') {
        execSearch();
      } else {
        timer = setTimeout(execSearch, 1000);
      }
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [search]);

  return (
    <MainContainer data-cy="page-container">
      <Header data-cy="header-user">
        <TitlePage data-cy="title-users">Users</TitlePage>
        <Can data-cy={AlmostRoles} roles={AlmostRoles}>
          <ButtonContainer data-cy="button-container-addTeammate">
            <Button
              dataCy="button-addUser"
              onClick={() => {
                setDialogOpen(true);
              }}
              type="button"
              disabled={loadingInvite || loadingMembers}
            >
              Add User
            </Button>
          </ButtonContainer>
        </Can>
      </Header>

      <MainContent data-cy="list-card">
        <SearchBar
          disabled={loadingMembers}
          search={search}
          placeholder="Search user"
          dataCy="input-searchUser"
          onChange={(e) => {
            if (e.target.value.length <= 60) {
              setSearch(e.target.value);
            }
          }}
          onClearInput={() => {
            setSearch('');
          }}
          loading={loadingMembers}
        />

        {loadingMembers && users.length === 0 ? (
          <LoadingContainer data-cy="loading-users-container">
            <Loading dataCy="loading-users" />
          </LoadingContainer>
        ) : (
          <ContentPagination dataCy="content-users">
            <Table
              loading={loadingMembers}
              columns={headerColumns}
              dataLength={users.length}
              next={searchUsers}
              hasMore={users.length < (paginationMeta?.total ?? 0)}
              noItensMessage="No users found"
            >
              {users.map((member) => (
                <TableRow
                  data-cy={`users-card-${member.id}-container`}
                  key={member.id}
                >
                  <Can data-cy={AlmostRoles} roles={AlmostRoles}>
                    {(currentRole?.level ?? 0) >=
                      (member.currentRole?.level ?? 0) &&
                    member.id !== user?.id &&
                    (user?.companyId === Number(COMPANY_ID) ||
                      user?.companyId === member.client?.id) ? (
                      <>
                        <TableImageCell
                          text={`${member.firstName} ${member.lastName}`}
                          subtitle={member.email}
                          dataCy="name"
                          type="icon"
                          src={member.accountFile?.path}
                        >
                          <UserIcon />
                        </TableImageCell>
                        <TableCell
                          dataCy={`users-${member.id}-client`}
                          text={member.client?.name ?? '-'}
                          textAlign="center"
                        />
                        {user?.companyId === Number(COMPANY_ID) ||
                        (user?.companyId === member.client?.id &&
                          (member.currentRole?.level ?? 0) < 4) ? (
                          <ItemGridCenter data-cy={`${member.id}-container`}>
                            <SelectData
                              data={member.rolesAux ?? []}
                              dataCy="select-userType"
                              name="select-userType"
                              onValueChange={(e) => {
                                handleRoleSelectChange(
                                  e,
                                  member.email,
                                  member.firstName,
                                  member.lastName,
                                  member.phone ?? ''
                                );
                              }}
                              defaultValue={member.roles[0]}
                            />
                          </ItemGridCenter>
                        ) : (
                          <ItemGridCenter data-cy="item-grid">
                            <TableCell
                              text={member.currentRole?.value ?? '-'}
                              textAlign="center"
                              data-cy={member.currentRole?.value}
                            />
                          </ItemGridCenter>
                        )}
                        <DropdownButtonContainer data-cy="dropdown-container">
                          <BaseDropdown
                            dataCy="dropdown-user"
                            onOpenChange={(isOpen) => {
                              setIsDropdownOpen(isOpen);
                            }}
                          >
                            <DropdownItem
                              data-cy={`option-edit`}
                              onClick={() => {
                                handleOpenModalEdit(member);
                              }}
                            >
                              Edit
                            </DropdownItem>
                            <DropdownItem
                              data-cy={`option-delete`}
                              onClick={() => {
                                handleOpenModalDelete(member);
                              }}
                            >
                              Delete
                            </DropdownItem>
                          </BaseDropdown>
                        </DropdownButtonContainer>
                      </>
                    ) : (
                      <>
                        <TableImageCell
                          text={`${member.firstName} ${member.lastName}`}
                          subtitle={member.email}
                          dataCy="user-card"
                          type="icon"
                          src={member.accountFile?.path ?? PersonIcon}
                        />
                        <TableCell
                          text={member.client?.name ?? '-'}
                          textAlign="center"
                        />
                        <TableCell
                          text={member.currentRole?.value ?? '-'}
                          textAlign="center"
                          data-cy={member.currentRole?.value}
                        />
                        <TableCell text="" textAlign="center" />
                      </>
                    )}
                  </Can>
                </TableRow>
              ))}
            </Table>
          </ContentPagination>
        )}
      </MainContent>
      <ModalAddUsers
        onAccept={inviteTeammateSubmit}
        onDecline={() => {
          setDialogOpen(false);
        }}
        open={dialogOpen}
        loading={loadingInvite}
        success={successRequest}
      />

      <ModalUpdateUser
        onAccept={handlerEditSubmit}
        onDecline={() => {
          setDialogEditOpen(false);
        }}
        onProfileChange={handleProfileChange}
        open={!isDropdownOpen && dialogEditOpen && emailEdit !== ''}
        loading={loadingEdit}
        id={idMemberToEdit}
        email={emailEdit}
        firstName={firstNameEdit}
        lastName={lastNameEdit}
        phone={phoneEdit}
        currentRole={roleEdit}
        profileSrc={profileImage}
      />

      <BaseModal
        onOpenChange={setDialogLoadingOpen}
        open={dialogLoadingOpen}
        dataCy="spinner-loading-content-modal"
        hiddenCloseButton={true}
      >
        <Center>
          <Loading src={LoadingSpinnerAnimated} />
        </Center>
      </BaseModal>

      <DialogModal
        dataCy="title-deleteUser"
        acceptText="Confirm"
        declineText="Cancel"
        open={!isDropdownOpen && isModalDeleteOpen}
        loading={loadingDelete}
        mainText={`Are you sure you want to delete ${
          userToDelete?.firstName ?? 'this user'
        } ${userToDelete?.lastName ?? ''}?`}
        onAccept={handleAcceptDelete}
        onDecline={handleDeclineDelete}
        onOpenChange={handleDeclineDelete}
      />
    </MainContainer>
  );
};
