import { type ChangeEvent, useCallback, useRef, useState } from 'react';

import { BaseModal } from '../BaseModal';
import { Button } from '../Button';
import { LazyImage } from '../LazyImage';
import {
  ButtonsContainer,
  ConfirmationDescription,
  ConfirmationTitle,
  Container,
  DeleteButton,
  DeleteIcon,
  Fieldset,
  ImageContainer,
  Input,
  Label,
  Loading,
} from './styles';

interface IUploadImageProps {
  label?: string;
  imageSrc?: string;
  onSelectImage: (file: File) => void;
  onRemoveImage?: () => void;
  onRemoveImagePreview: () => void;
  loadingDeleteImage?: boolean;
  loading?: boolean;
}

export const UploadImageModal = ({
  label,
  imageSrc,
  onSelectImage,
  onRemoveImage,
  onRemoveImagePreview,
  loadingDeleteImage = false,
  loading = false,
}: IUploadImageProps): JSX.Element => {
  const [isModalConfirmationOpen, setIsModalConfirmationOpen] = useState(false);
  const [imageSrcPreview, setImageSrcPreview] = useState('');
  const fileInputRef = useRef<HTMLInputElement>(null);

  const removeIconImage = !!imageSrc || !!imageSrcPreview;

  const handleRemoveImagePreview = useCallback(() => {
    setImageSrcPreview('');
    fileInputRef.current && (fileInputRef.current.value = '');
    onRemoveImagePreview();
  }, [onRemoveImagePreview]);

  const handleOnChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      if (target.files?.length === 0) {
        handleRemoveImagePreview();
      }

      const newFile = target.files?.item(0);
      if (newFile) {
        setImageSrcPreview(URL.createObjectURL(newFile));
        onSelectImage(newFile);
      }
    },
    [handleRemoveImagePreview, onSelectImage]
  );

  const handleDeleteClick = () => {
    if (imageSrcPreview) {
      handleRemoveImagePreview();
    } else {
      setIsModalConfirmationOpen(true);
    }
  };

  const handleCancelClick = () => {
    setIsModalConfirmationOpen(false);
  };

  const handleConfirmClick = () => {
    setIsModalConfirmationOpen(false);
    onRemoveImage?.();
  };

  return (
    <Container>
      {removeIconImage && (
        <DeleteButton
          data-cy="button-delete"
          type="button"
          onClick={handleDeleteClick}
          disabled={loadingDeleteImage}
        >
          <DeleteIcon />
        </DeleteButton>
      )}
      <ImageContainer
        data-cy="upload-imageContainer"
        hasImage={!!imageSrcPreview || !!imageSrc}
      >
        {loadingDeleteImage ? (
          <Loading />
        ) : (
          <LazyImage src={imageSrcPreview || imageSrc} dataCy="logo-client" />
        )}
      </ImageContainer>
      <Fieldset hasLabel={!!label}>
        <Input
          ref={fileInputRef}
          accept="image/*"
          onChange={handleOnChange}
          data-cy="button-addLogo"
          type="file"
          disabled={loading}
        />
        {label && <Label data-cy={label}>{label}</Label>}
      </Fieldset>

      <BaseModal
        open={isModalConfirmationOpen}
        onOpenChange={() => {
          setIsModalConfirmationOpen(false);
        }}
        zIndex={10}
      >
        <ConfirmationTitle data-cy="text-deleteImage">
          Delete Image
        </ConfirmationTitle>
        <ConfirmationDescription data-cy="description-deleteImage">
          Are you sure you want to delete the image?
        </ConfirmationDescription>

        <ButtonsContainer>
          <Button
            type="button"
            dataCy="button-cancel"
            styleType="outline"
            onClick={handleCancelClick}
          >
            Cancel
          </Button>

          <Button
            type="button"
            dataCy="button-confirm"
            onClick={handleConfirmClick}
          >
            Confirm
          </Button>
        </ButtonsContainer>
      </BaseModal>
    </Container>
  );
};
