import { type KeyboardEventHandler, type ReactNode, forwardRef } from 'react';

import * as SelectPrimitive from '@radix-ui/react-select';

import {
  ArrowDown,
  ArrowDownContainer,
  ArrowDownIcon,
  ArrowUp,
  ArrowUpContainer,
  CustomItem,
  CustomViewport,
  IconContainer,
  IndicatorContainer,
  SelectContent,
  SelectIndicatorIcon,
  SelectTrigger,
} from './styles';

export interface IBaseSelectProps extends SelectPrimitive.SelectProps {
  children: ReactNode;
  customContentStyle?: React.CSSProperties;
  overwritePortalHeight?: number;
  dataCy?: string;
  defaultValue?: string;
  /**
   * @deprecated
   * Please use `onKeyDown` instead.
   */
  onKeyPress?: KeyboardEventHandler<HTMLDivElement>;
  onKeyDown?: KeyboardEventHandler<HTMLDivElement>;
  placeholder?: string;
  themeStyle?: 'admin' | 'default';
  zIndex?: number;
  id?: string;
}

export const BaseSelect = ({
  children,
  customContentStyle,
  overwritePortalHeight,
  dataCy = 'select-field',
  onKeyPress,
  onKeyDown,
  placeholder = 'Select…',
  themeStyle = 'default',
  zIndex,
  ...props
}: IBaseSelectProps): JSX.Element => (
  <SelectPrimitive.Root data-cy="test" {...props}>
    <SelectTrigger data-cy={dataCy} $themeStyle={themeStyle}>
      <SelectPrimitive.Value
        data-cy={placeholder.toLowerCase()}
        placeholder={placeholder}
      />

      <IconContainer data-cy="icon-container">
        <ArrowDownIcon data-cy="arrow-down" />
      </IconContainer>
    </SelectTrigger>
    <SelectPrimitive.Portal data-cy="select-portal">
      <SelectContent
        $overwriteHeight={overwritePortalHeight}
        $zIndex={zIndex}
        data-cy="select-content-container"
        onKeyDown={onKeyDown ?? onKeyPress}
        style={customContentStyle}
      >
        {children}
      </SelectContent>
    </SelectPrimitive.Portal>
  </SelectPrimitive.Root>
);

export interface IBaseSelectItemTextProps
  extends SelectPrimitive.SelectItemTextProps {}

export const BaseSelectItemText = forwardRef<
  HTMLDivElement,
  IBaseSelectItemTextProps
  // eslint-disable-next-line no-restricted-syntax
>(function BaseSelectItemText({ ...props }, ref): JSX.Element {
  return <SelectPrimitive.ItemText ref={ref} {...props} />;
});

export interface IBaseSelectArrowUpProps
  extends SelectPrimitive.SelectScrollUpButtonProps {}

export const BaseSelectArrowUp = forwardRef<
  HTMLDivElement,
  IBaseSelectArrowUpProps
  // eslint-disable-next-line no-restricted-syntax
>(function BaseSelectArrowUp({ ...props }, ref): JSX.Element {
  return (
    <ArrowUpContainer ref={ref} {...props}>
      <ArrowUp />
    </ArrowUpContainer>
  );
});

export interface IBaseSelectArrowDownProps
  extends SelectPrimitive.SelectScrollDownButtonProps {}

export const BaseSelectArrowDown = forwardRef<
  HTMLDivElement,
  IBaseSelectArrowDownProps
  // eslint-disable-next-line no-restricted-syntax
>(function BaseSelectArrowDown({ ...props }, ref): JSX.Element {
  return (
    <ArrowDownContainer ref={ref} {...props}>
      <ArrowDown />
    </ArrowDownContainer>
  );
});

export interface IBaseSelectIndicatorProps
  extends SelectPrimitive.SelectItemIndicatorProps {}

export const BaseSelectIndicator = forwardRef<
  HTMLSpanElement,
  IBaseSelectIndicatorProps
  // eslint-disable-next-line no-restricted-syntax
>(function BaseSelectIndicator({ children, ...props }, ref): JSX.Element {
  return (
    <IndicatorContainer ref={ref} {...props}>
      {children ?? <SelectIndicatorIcon />}
    </IndicatorContainer>
  );
});

export interface IBaseSelectViewportProps
  extends SelectPrimitive.SelectViewportProps {}

export const BaseSelectViewport = forwardRef<
  HTMLDivElement,
  IBaseSelectViewportProps
  // eslint-disable-next-line no-restricted-syntax
>(function BaseSelectViewport({ ...props }, ref): JSX.Element {
  return <CustomViewport ref={ref} {...props} />;
});

export interface IBaseSelectItemProps extends SelectPrimitive.SelectItemProps {}

export const BaseSelectItem = forwardRef<
  HTMLDivElement,
  IBaseSelectItemProps
  // eslint-disable-next-line no-restricted-syntax
>(function BaseSelectItem({ ...props }, ref): JSX.Element {
  return <CustomItem ref={ref} {...props} />;
});
